{
  "asof": "2024-02-25T16:24:36.272449",
      "active_count": {
        "displayname": "Active Users",
        "value": "5,041,681"
      },
      "deleted_count": {
        "displayname": "Deleted Users",
        "value": "358,084"
      },
      "total_count": {
        "displayname": "Total Users",
        "value": "5,399,765"
      },


    "blocked": [
      {
        "Handle": "jordanbpeterson.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:aeuetvb7vac3xay76nkphbks",
        "block_count": 17390,
        "did": "did:plc:aeuetvb7vac3xay76nkphbks"
      },
      {
        "Handle": "aify.co",
        "ProfileURL": "https://bsky.app/profile/did:plc:s3kgjpyoec2fd7ztruqiaxwx",
        "block_count": 13646,
        "did": "did:plc:s3kgjpyoec2fd7ztruqiaxwx"
      },
      {
        "Handle": "endwokeness.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:byet53dwfmr5at7xk56zwmxv",
        "block_count": 12078,
        "did": "did:plc:byet53dwfmr5at7xk56zwmxv"
      },
      {
        "Handle": "shortcovid.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:rjlu6npi554qkz2jcvdt7mc3",
        "block_count": 9815,
        "did": "did:plc:rjlu6npi554qkz2jcvdt7mc3"
      },
      {
        "Handle": "anonymous.expectus.fyi",
        "ProfileURL": "https://bsky.app/profile/did:plc:xfqcsi7wuwedeqaa5m7aih44",
        "block_count": 8890,
        "did": "did:plc:xfqcsi7wuwedeqaa5m7aih44"
      },
      {
        "Handle": "90sanime.pics",
        "ProfileURL": "https://bsky.app/profile/did:plc:5krm4pb5gecb5uawvgr7uxuu",
        "block_count": 8542,
        "did": "did:plc:5krm4pb5gecb5uawvgr7uxuu"
      },
      {
        "Handle": "mdbreathe.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:cmo3ypyyvybcgyi2tg2x4sge",
        "block_count": 8339,
        "did": "did:plc:cmo3ypyyvybcgyi2tg2x4sge"
      },
      {
        "Handle": "thedevil.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:k2weqoffrljoi7d45jjhjaqk",
        "block_count": 7927,
        "did": "did:plc:k2weqoffrljoi7d45jjhjaqk"
      },
      {
        "Handle": "catswithaura.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:wwakmfq74pvducx2pbbdxhlg",
        "block_count": 7521,
        "did": "did:plc:wwakmfq74pvducx2pbbdxhlg"
      },
      {
        "Handle": "9gag.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:56eikwlvdd4htq727624spkg",
        "block_count": 7369,
        "did": "did:plc:56eikwlvdd4htq727624spkg"
      },
      {
        "Handle": "afdberlin.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:vzakzcxo3dqgjvlgmqlakcb5",
        "block_count": 7184,
        "did": "did:plc:vzakzcxo3dqgjvlgmqlakcb5"
      },
      {
        "Handle": "womensart1.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:jfpub26kfrtponcqzi7i7udl",
        "block_count": 6361,
        "did": "did:plc:jfpub26kfrtponcqzi7i7udl"
      },
      {
        "Handle": "artificial.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:gr3zsqab63cfvv7nao4mrh7v",
        "block_count": 6016,
        "did": "did:plc:gr3zsqab63cfvv7nao4mrh7v"
      },
      {
        "Handle": "ewerickson.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:2gwoe546lp565vxmzzy2emsl",
        "block_count": 5993,
        "did": "did:plc:2gwoe546lp565vxmzzy2emsl"
      },
      {
        "Handle": "poeticalphotos.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:ha5tvry6kkxiujam6kyjgagg",
        "block_count": 5871,
        "did": "did:plc:ha5tvry6kkxiujam6kyjgagg"
      },
      {
        "Handle": "julianreichelt.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:hwbjiajbxxij27fzdxwgp7h7",
        "block_count": 5867,
        "did": "did:plc:hwbjiajbxxij27fzdxwgp7h7"
      },
      {
        "Handle": "funnyordie.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:m4wkkbc4k5e46hus7zy3tijd",
        "block_count": 5773,
        "did": "did:plc:m4wkkbc4k5e46hus7zy3tijd"
      },
      {
        "Handle": "culture-crit.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:uvsztbhnf2gcplblz5hitxc7",
        "block_count": 5700,
        "did": "did:plc:uvsztbhnf2gcplblz5hitxc7"
      },
      {
        "Handle": "aisky.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:kazwkrel3bvgeqm3wdiydwdf",
        "block_count": 5615,
        "did": "did:plc:kazwkrel3bvgeqm3wdiydwdf"
      },
      {
        "Handle": "othingstodo.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:i6r2cszqypv2qkp5wvppkndo",
        "block_count": 5601,
        "did": "did:plc:i6r2cszqypv2qkp5wvppkndo"
      }
    ],
    "blocked_aid": {
      "did:plc:2gwoe546lp565vxmzzy2emsl": "bafkreigkyri2ge24a2gjvbyfqhpv72ean3kqdr5im6e3njdgfcosgtaly4",
      "did:plc:56eikwlvdd4htq727624spkg": "bafkreic2xkbo646nxl44z3zk2zeighlvsgltoqjoolc3ihc26p4sdxityu",
      "did:plc:5krm4pb5gecb5uawvgr7uxuu": "bafkreie6vz5gykxxdf777yiirarw3eyvycbg46to6dknynbxwqrcsl5mfu",
      "did:plc:aeuetvb7vac3xay76nkphbks": "bafkreibnus2odyijekzkiarrthjwwjjaxbqor5ueopfsa3ezk3doyd3t2a",
      "did:plc:byet53dwfmr5at7xk56zwmxv": "bafkreie5wkm74v6kewoeukcla2hdvzfcfsmqvtm5mhnxdvabgjd2fc2jvm",
      "did:plc:cmo3ypyyvybcgyi2tg2x4sge": "bafkreicvorlfinnmygp5ibd65wjvr6tq526fkaksgsrls7v4bw3caluko4",
      "did:plc:gr3zsqab63cfvv7nao4mrh7v": "bafkreias26vb2aujzaktxnnzgkqkydf3unkdu4qd6l765fdvqbt5xe6hma",
      "did:plc:ha5tvry6kkxiujam6kyjgagg": "bafkreigdoleeclxuwukhhqwqsftrofuazhqhvab377v3tyk4jazkvn2ixi",
      "did:plc:hwbjiajbxxij27fzdxwgp7h7": "bafkreidsd5niiokjiqkrgi3ko444rne6t2qhaeyvyhvz7ahjgyyih3xzie",
      "did:plc:i6r2cszqypv2qkp5wvppkndo": "bafkreia6amuooyaq4uk5smuyhks6wqdipso4yjsv46fszxq6zuvkzkjhqy",
      "did:plc:jfpub26kfrtponcqzi7i7udl": "bafkreib35vtob7wsqhye4t3tgkoprye5qeqv4fauedcqomfm3yj6oae52i",
      "did:plc:k2weqoffrljoi7d45jjhjaqk": "bafkreiamm5zsg4fs5gt3qv6ljam57a45ulb2ova5zk2f7sagr4cvowiroq",
      "did:plc:kazwkrel3bvgeqm3wdiydwdf": "bafkreieihktyf7lcy3votcdxkvahtzyyroak34uf42zae4i2be76fnupkm",
      "did:plc:m4wkkbc4k5e46hus7zy3tijd": "bafkreibxjsxyd452tupsqjw252jjnsaqaooyqdhvrdafsu3rhprtcsktfi",
      "did:plc:rjlu6npi554qkz2jcvdt7mc3": "bafkreia7nn5zcs7eftzihvaqhcqd2wge2s4xyel2rebpvtk7p4sjwwae3y",
      "did:plc:s3kgjpyoec2fd7ztruqiaxwx": "bafkreigstp5o3tazxxln47estmi3fvq52wvl3ktnn4bldxnw4mueb5wppa",
      "did:plc:uvsztbhnf2gcplblz5hitxc7": "bafkreieximiueazfy7vqej35sea7477l75tweiipvthjicwqzxz3njy52u",
      "did:plc:vzakzcxo3dqgjvlgmqlakcb5": "bafkreibcalpe4ceeh27wuu42irlkgb3rm6dcxyk3bqcfe7xnjpxxiqojsy",
      "did:plc:wwakmfq74pvducx2pbbdxhlg": "bafkreieeqwlz7l4lk6eztvyfnowockjstvksrqsfveb7hk2rv5rkfuihtm",
      "did:plc:xfqcsi7wuwedeqaa5m7aih44": "bafkreic6auduax3rkmtm6xgaetan5osdm454a4y5wc5ryfcjkg5gnfqhmu"
    },
    "blockers": [
      {
        "Handle": "godemperorof.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:aaelnwlxvflllswegmearbwo",
        "block_count": 538414,
        "did": "did:plc:aaelnwlxvflllswegmearbwo"
      },
      {
        "Handle": "chiefkeef.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:e4fsmjcj33khuqi6prspceiy",
        "block_count": 32919,
        "did": "did:plc:e4fsmjcj33khuqi6prspceiy"
      },
      {
        "Handle": "joshuajoy.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:vvzu3m7xgl6nldqjbp25pitj",
        "block_count": 11337,
        "did": "did:plc:vvzu3m7xgl6nldqjbp25pitj"
      },
      {
        "Handle": "reishoku.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:yjxotndhnf4jsldpzh3dbbhe",
        "block_count": 8618,
        "did": "did:plc:yjxotndhnf4jsldpzh3dbbhe"
      },
      {
        "Handle": "benclombardo.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:4plzygasgydhuirdrlflqbjo",
        "block_count": 8353,
        "did": "did:plc:4plzygasgydhuirdrlflqbjo"
      },
      {
        "Handle": "populuxe.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:cgxxlxuypmkdqdcpioqny5r6",
        "block_count": 7787,
        "did": "did:plc:cgxxlxuypmkdqdcpioqny5r6"
      },
      {
        "Handle": "thewrittentevs.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:mauk6khnlclkr5k2hnezgmja",
        "block_count": 5400,
        "did": "did:plc:mauk6khnlclkr5k2hnezgmja"
      },
      {
        "Handle": "likeamilkdud.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:d2c3m3xnz4e3x774bq7w43pc",
        "block_count": 5251,
        "did": "did:plc:d2c3m3xnz4e3x774bq7w43pc"
      },
      {
        "Handle": "csam.okconfirmed.com",
        "ProfileURL": "https://bsky.app/profile/did:plc:o6p7fbjv2xyijweh5uexdeun",
        "block_count": 4569,
        "did": "did:plc:o6p7fbjv2xyijweh5uexdeun"
      },
      {
        "Handle": "fresh-newlook.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:6odst3452scxthx7w3v2tiav",
        "block_count": 4346,
        "did": "did:plc:6odst3452scxthx7w3v2tiav"
      },
      {
        "Handle": "sunsetsnow.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:axrs65zc6hj3m5axp3fth6pw",
        "block_count": 4263,
        "did": "did:plc:axrs65zc6hj3m5axp3fth6pw"
      },
      {
        "Handle": "pupgamer.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:mafslxu4vgugbybjzsuqxdqr",
        "block_count": 4243,
        "did": "did:plc:mafslxu4vgugbybjzsuqxdqr"
      },
      {
        "Handle": "itwont.run",
        "ProfileURL": "https://bsky.app/profile/did:plc:nyykk5p7laar725gdnypopsh",
        "block_count": 4160,
        "did": "did:plc:nyykk5p7laar725gdnypopsh"
      },
      {
        "Handle": "sunagimochan.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:lsoslz2tnl5rudrd7toaxly3",
        "block_count": 3554,
        "did": "did:plc:lsoslz2tnl5rudrd7toaxly3"
      },
      {
        "Handle": "hamandegger.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:wgiw4zvjhfhhubf6dtocjern",
        "block_count": 3462,
        "did": "did:plc:wgiw4zvjhfhhubf6dtocjern"
      },
      {
        "Handle": "blueotter.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:yq6oqday6xtoqcgigdotnglw",
        "block_count": 3376,
        "did": "did:plc:yq6oqday6xtoqcgigdotnglw"
      },
      {
        "Handle": "palomar.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:2col443nxnlr3qxmlh53u2oi",
        "block_count": 3321,
        "did": "did:plc:2col443nxnlr3qxmlh53u2oi"
      },
      {
        "Handle": "nanamiasari.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:ptlddkaqekq5xj4icqh3btu3",
        "block_count": 3320,
        "did": "did:plc:ptlddkaqekq5xj4icqh3btu3"
      },
      {
        "Handle": "elaxor.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:ofca62ov5uoae3j772j5vxkx",
        "block_count": 3210,
        "did": "did:plc:ofca62ov5uoae3j772j5vxkx"
      },
      {
        "Handle": "john88.bsky.social",
        "ProfileURL": "https://bsky.app/profile/did:plc:nhfzuw3qpspigcbhopyjyoxg",
        "block_count": 2987,
        "did": "did:plc:nhfzuw3qpspigcbhopyjyoxg"
      }
    ],
    "blockers_aid": {
      "did:plc:2col443nxnlr3qxmlh53u2oi": "",
      "did:plc:4plzygasgydhuirdrlflqbjo": "bafkreidmvofs6nmkoqadp4vkvh26jzh73bzvf5s2ajvt5ztnkqxneyayvq",
      "did:plc:6odst3452scxthx7w3v2tiav": "bafkreid4znwozgnn5vz7jhk5wsub73dkwnqf3hu4cs7u5njewqiixugi4q",
      "did:plc:aaelnwlxvflllswegmearbwo": "bafkreicekc6sdf7i5aibzo6yhmuvykwj43gruxmyc5wsmgkahflswwez6a",
      "did:plc:axrs65zc6hj3m5axp3fth6pw": "bafkreicqwvuqqhzi6vzexyi7c5arfov4cqr26hkxggameqmd3nkldovud4",
      "did:plc:cgxxlxuypmkdqdcpioqny5r6": "bafkreiaaaksgtbfk7wvuwrzgyrssvfqm7gzxmilsf6w4jz33eiz6auwyo4",
      "did:plc:d2c3m3xnz4e3x774bq7w43pc": "bafkreig672aogrqgj6hj3fhilgetxrfty4pf3vv2q25sjzm4uicnvs3wru",
      "did:plc:e4fsmjcj33khuqi6prspceiy": "bafkreifykraakgmpblk7q574amv23yffetp43fmxubja4vnhu74rob3ida",
      "did:plc:lsoslz2tnl5rudrd7toaxly3": "bafkreicqe2lokrhlmxi2zvygqeq2wycap6fsfq3ljy5l7n36kxggyzghee",
      "did:plc:mafslxu4vgugbybjzsuqxdqr": "bafkreicmimmf727toysnirqlrngcr3dtdcqq76dklzdqhsz7gkaz6enpli",
      "did:plc:mauk6khnlclkr5k2hnezgmja": "bafkreigbmijkhof4qsutjttodry4h33euthlu6xcvezzxlie2aejuhteaq",
      "did:plc:nhfzuw3qpspigcbhopyjyoxg": "",
      "did:plc:nyykk5p7laar725gdnypopsh": "bafkreidao55d575zbvdekot3zvtqq63lj4ebdj2ydodjy4n7o4vr3dkstm",
      "did:plc:o6p7fbjv2xyijweh5uexdeun": "bafkreiepeuh6mz4pc2mzmjyysizxz47zcm454tednxk5ntrmu5miuvjgr4",
      "did:plc:ofca62ov5uoae3j772j5vxkx": "bafkreiezuxscejajajbivlygsbfmeqkbquwugp7hltxcetcbzyjydhjroe",
      "did:plc:ptlddkaqekq5xj4icqh3btu3": "bafkreidrkrwvnj5kvnyyhgmbvcqrytwjuxh2kcogfhv7ipnj6qjqpbicdu",
      "did:plc:vvzu3m7xgl6nldqjbp25pitj": "bafkreifjxjfheaynu5cvf4zxqkw3jhr5urwkqykklq64bggdghhmeddfji",
      "did:plc:wgiw4zvjhfhhubf6dtocjern": "bafkreibkkfry5co2opt3itgcx3y2dkuqwpy3kzwkmf5b6b7q6i76qutduu",
      "did:plc:yjxotndhnf4jsldpzh3dbbhe": "bafkreifomfskzqebqe2h7as2m2e3g6tmnmzintulxgw7gdvvdmhesdbq4u",
      "did:plc:yq6oqday6xtoqcgigdotnglw": "bafkreif7yd74dsurrjboyvrqwmwdbhpqs3w4eku36gmcw2vfqk4vncrnyq"
    },

  "blocked24": [
    {
      "Handle": "william1ng.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:srszfyoy7rnvbayspnvo6gzk",
      "block_count": 472,
      "did": "did:plc:srszfyoy7rnvbayspnvo6gzk"
    },
    {
      "Handle": "permabanned.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:lq27lgik3ku2qg6zfxmil5xo",
      "block_count": 301,
      "did": "did:plc:lq27lgik3ku2qg6zfxmil5xo"
    },
    {
      "Handle": "whstancil.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:7umvpuxe2vbrc3zrzuquzniu",
      "block_count": 242,
      "did": "did:plc:7umvpuxe2vbrc3zrzuquzniu"
    },
    {
      "Handle": "nihilinterit.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:gvgcih7d6m3e2hvyq46igazf",
      "block_count": 222,
      "did": "did:plc:gvgcih7d6m3e2hvyq46igazf"
    },
    {
      "Handle": "thallus.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:3j6zhuwhlaq6ce3whn6psuzf",
      "block_count": 202,
      "did": "did:plc:3j6zhuwhlaq6ce3whn6psuzf"
    },
    {
      "Handle": "terfslayer.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:inbqnvflb7h2yiiaz7csx3wc",
      "block_count": 174,
      "did": "did:plc:inbqnvflb7h2yiiaz7csx3wc"
    },
    {
      "Handle": "saltyserpent.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:aev34tr3qek5p4xpgkqte7ve",
      "block_count": 127,
      "did": "did:plc:aev34tr3qek5p4xpgkqte7ve"
    },
    {
      "Handle": "therealericg765.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:6m3d4kvetsgyuv63fal2nfeh",
      "block_count": 126,
      "did": "did:plc:6m3d4kvetsgyuv63fal2nfeh"
    },
    {
      "Handle": "bargdaffy.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:k5igcriqmplffxazxux36dk3",
      "block_count": 124,
      "did": "did:plc:k5igcriqmplffxazxux36dk3"
    },
    {
      "Handle": "sarahiscute.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:kkwgudwujvnik2wwohuxr36q",
      "block_count": 111,
      "did": "did:plc:kkwgudwujvnik2wwohuxr36q"
    },
    {
      "Handle": "rexs1gma.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:qdriunexx3nobe5kpltycouy",
      "block_count": 106,
      "did": "did:plc:qdriunexx3nobe5kpltycouy"
    },
    {
      "Handle": "schierkujaschierk.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:pbeh735amil4zwnwfidbwawx",
      "block_count": 101,
      "did": "did:plc:pbeh735amil4zwnwfidbwawx"
    },
    {
      "Handle": "lofibaby.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:jq6adp6xpuywzmkkq5xhgsli",
      "block_count": 101,
      "did": "did:plc:jq6adp6xpuywzmkkq5xhgsli"
    },
    {
      "Handle": "navin5u.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:izwkrpapa4baoz4cfxkyri3t",
      "block_count": 100,
      "did": "did:plc:izwkrpapa4baoz4cfxkyri3t"
    },
    {
      "Handle": "contexthuman5u.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:3w6lqhoiwb4oyxkq4vkezmoh",
      "block_count": 100,
      "did": "did:plc:3w6lqhoiwb4oyxkq4vkezmoh"
    },
    {
      "Handle": "200motels.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:7hdkruwu4o5ctjvgasmoq7kz",
      "block_count": 95,
      "did": "did:plc:7hdkruwu4o5ctjvgasmoq7kz"
    },
    {
      "Handle": "nowarbutclasswar.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:l4yavyh246boe7uzo4cua2ns",
      "block_count": 91,
      "did": "did:plc:l4yavyh246boe7uzo4cua2ns"
    },
    {
      "Handle": "sdesk.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:4mbllyfo5h6macrye35rg2dv",
      "block_count": 90,
      "did": "did:plc:4mbllyfo5h6macrye35rg2dv"
    },
    {
      "Handle": "meginstarlight.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:3le6pwxcgwunzz6o77x2z7ar",
      "block_count": 84,
      "did": "did:plc:3le6pwxcgwunzz6o77x2z7ar"
    },
    {
      "Handle": "lukemaskwalker.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:cnjc3bgo6werfibdv7ztbwbs",
      "block_count": 82,
      "did": "did:plc:cnjc3bgo6werfibdv7ztbwbs"
    }
  ],
  "blockers24": [
    {
      "Handle": "godemperorof.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:aaelnwlxvflllswegmearbwo",
      "block_count": 6419,
      "did": "did:plc:aaelnwlxvflllswegmearbwo"
    },
    {
      "Handle": "hatac009.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:d7pkd2naqqxa6eutobyokvp5",
      "block_count": 1080,
      "did": "did:plc:d7pkd2naqqxa6eutobyokvp5"
    },
    {
      "Handle": "whydoilikethis.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:g2xshwj4o33b5wzxs3xspfxk",
      "block_count": 539,
      "did": "did:plc:g2xshwj4o33b5wzxs3xspfxk"
    },
    {
      "Handle": "cynanthrope.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:5gspcix3gh6xlpq7cpud3igc",
      "block_count": 409,
      "did": "did:plc:5gspcix3gh6xlpq7cpud3igc"
    },
    {
      "Handle": "jeanbath.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:nsf5dhzypf4e3j4ujlxsw6mj",
      "block_count": 299,
      "did": "did:plc:nsf5dhzypf4e3j4ujlxsw6mj"
    },
    {
      "Handle": "fierydarkstar.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:ni3wjvylk76d5q7mk665bgac",
      "block_count": 245,
      "did": "did:plc:ni3wjvylk76d5q7mk665bgac"
    },
    {
      "Handle": "joshaustin.tech",
      "ProfileURL": "https://bsky.app/profile/did:plc:ejn42lyctdwfin3ymjoenebs",
      "block_count": 236,
      "did": "did:plc:ejn42lyctdwfin3ymjoenebs"
    },
    {
      "Handle": "john88.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:nhfzuw3qpspigcbhopyjyoxg",
      "block_count": 221,
      "did": "did:plc:nhfzuw3qpspigcbhopyjyoxg"
    },
    {
      "Handle": "pyritohedron.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:kc6b7l5qnaylgnrkpo4apnms",
      "block_count": 190,
      "did": "did:plc:kc6b7l5qnaylgnrkpo4apnms"
    },
    {
      "Handle": "murxibald.vegantifa.de",
      "ProfileURL": "https://bsky.app/profile/did:plc:3tahovyvo3t2cp5rshxalw33",
      "block_count": 190,
      "did": "did:plc:3tahovyvo3t2cp5rshxalw33"
    },
    {
      "Handle": "shisuiten.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:afiyi64xi7t2jppwbfwujvrl",
      "block_count": 173,
      "did": "did:plc:afiyi64xi7t2jppwbfwujvrl"
    },
    {
      "Handle": "helinon.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:mjllre6n522jmm6wfaguifuw",
      "block_count": 160,
      "did": "did:plc:mjllre6n522jmm6wfaguifuw"
    },
    {
      "Handle": "limbischessystem.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:laox4youuo2r5bw2nxlfkxla",
      "block_count": 149,
      "did": "did:plc:laox4youuo2r5bw2nxlfkxla"
    },
    {
      "Handle": "enyantiomer.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:rcpdouakmhwfitbxf4wlf73g",
      "block_count": 134,
      "did": "did:plc:rcpdouakmhwfitbxf4wlf73g"
    },
    {
      "Handle": "kristianharstad.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:aui4nwz642bg4edbkvexogcw",
      "block_count": 114,
      "did": "did:plc:aui4nwz642bg4edbkvexogcw"
    },
    {
      "Handle": "simomo.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:bsfkrvkgspgv7nx7yyvn6kfy",
      "block_count": 103,
      "did": "did:plc:bsfkrvkgspgv7nx7yyvn6kfy"
    },
    {
      "Handle": "nationalist.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:vpaoviy4grvnajgaze6642tf",
      "block_count": 100,
      "did": "did:plc:vpaoviy4grvnajgaze6642tf"
    },
    {
      "Handle": "lunastarlight123.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:j5azar2i2m33clf4gx6s3q5l",
      "block_count": 99,
      "did": "did:plc:j5azar2i2m33clf4gx6s3q5l"
    },
    {
      "Handle": "666naberius666.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:kyj73ifujf6tnhe7s5wpoam4",
      "block_count": 97,
      "did": "did:plc:kyj73ifujf6tnhe7s5wpoam4"
    },
    {
      "Handle": "ponderosa121.bsky.social",
      "ProfileURL": "https://bsky.app/profile/did:plc:6icr4tipspgctwsl24lv5r3y",
      "block_count": 95,
      "did": "did:plc:6icr4tipspgctwsl24lv5r3y"
    }
  ],

    "averageNumberOfBlocked": {
      "displayname": "Average Number of Users Blocked",
      "value": 5.29
    },
    "averageNumberOfBlocks": {
      "displayname": "Average Number of Blocks",
      "value": "12.83"
    },
    "numberBlock1": {
      "displayname": "Number of Users Blocking 1 User",
      "value": "188,265"
    },
    "numberBlocked1": {
      "displayname": "Number of Users Blocked by 1 User",
      "value": "602,973"
    },
    "numberBlocked101and1000": {
      "displayname": "Number of Users Blocked by 101-1000 Users",
      "value": "5,551"
    },
    "numberBlocked2and100": {
      "displayname": "Number of Users Blocked by 2-100 Users",
      "value": "450,770"
    },
    "numberBlockedGreaterThan1000": {
      "displayname": "Number of Users Blocked by More than 1000 Users",
      "value": "525"
    },
    "numberBlocking101and1000": {
      "displayname": "Number of Users Blocking 101-1000 Users",
      "value": "9,224"
    },
    "numberBlocking2and100": {
      "displayname": "Number of Users Blocking 2-100 Users",
      "value": "239,335"
    },
    "numberBlockingGreaterThan1000": {
      "displayname": "Number of Users Blocking More than 1000 Users",
      "value": "124"
    },
    "numberOfTotalBlocks": {
      "displayname": "Number of Total Blocks",
      "value": "5,606,317"
    },
    "numberOfUniqueUsersBlocked": {
      "displayname": "Number of Unique Users Blocked",
      "value": "1,059,819"
    },
    "numberOfUniqueUsersBlocking": {
      "displayname": "Number of Unique Users Blocking",
      "value": "436,948"
    },
    "percentNumberBlocked1": {
      "displayname": "Percent of Users Blocked by 1 User",
      "value": 56.89
    },
    "percentNumberBlocked101and1000": {
      "displayname": "Percent of Users Blocked by 101-1000 Users",
      "value": 0.52
    },
    "percentNumberBlocked2and100": {
      "displayname": "Percent of Users Blocked by 2-100 Users",
      "value": 42.53
    },
    "percentNumberBlockedGreaterThan1000": {
      "displayname": "Percent of Users Blocked by More than 1000 Users",
      "value": 0.05
    },
    "percentNumberBlocking1": {
      "displayname": "Percent of Users Blocking 1 User",
      "value": 43.09
    },
    "percentNumberBlocking101and1000": {
      "displayname": "Percent of Users Blocking 101-1000 Users",
      "value": 2.11
    },
    "percentNumberBlocking2and100": {
      "displayname": "Percent of Users Blocking 2-100 Users",
      "value": 54.77
    },
    "percentNumberBlockingGreaterThan1000": {
      "displayname": "Percent of Users Blocking More than 1000 Users",
      "value": 0.03
    },
    "percentUsersBlocked": {
      "displayname": "Percent Users Blocked",
      "value": 19.63
    },
    "percentUsersBlocking": {
      "displayname": "Percent Users Blocking",
      "value": 8.09
    },
    "totalUsers": {
      "displayname": "Total Users",
      "value": "5,399,765"
    }
}
